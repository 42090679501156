import tw from 'twin.macro';

export const SectionIntroduction = tw.h5`font-bold text-primary-500 mb-4 text-center`;
export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
export const SectionSubheading = tw.h5`font-bold text-primary-500`;
export const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600 max-w-xl w-full text-center`;

export const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
export const Title = tw.span`text-lg lg:text-xl font-semibold`;
export const Description = tw.span`mt-1 sm:mt-4 font-medium text-gray-600 leading-loose`;

export const SubHeading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
export const SubTitle = tw.span`text-lg lg:text-xl font-semibold`;
export const SubDescription = tw.span`mt-1 sm:mt-4 font-medium text-gray-600 leading-loose`;
